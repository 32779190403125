<template>
  <div class="s-bg-primary s-text-white s-text-center s-p-5 s-hero">
    <p class="s-font-semibold">{{ title }}</p>
    <slot />
  </div>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        requred: true,
      },
    },
  }
</script>

<style scoped>
  .s-hero {
    position: sticky;
    top: 0;
  }
</style>
