<template>
  <select :value="value" @change="handleChange">
    <option value="1">最近一個月</option>
    <option value="3">最近三個月</option>
    <option value="6">最近六個月</option>
  </select>
</template>

<script>
  export default {
    model: {
      event: 'change',
    },
    props: {
      value: {
        type: String,
        required: true,
      },
    },
    methods: {
      handleChange (e) {
        this.$emit('change', e.target.value)
      },
    },
  }
</script>

<style scoped>
  select {
    background: var(--s-white);
    border: 1px solid var(--s-gray-dark);
    border-radius: 0.5rem;
    display: block;
    width: 100%;
    padding: 1rem 0.75rem;
  }
</style>
