import withFetcher from '@/mixins/liff/withFetcher'
import Hero from '@/components/Page/Liff/MemberCard/Hero'
import Pagination from '@/components/Page/Liff/MemberCard/Pagination'
import PeriodSelect from '@/components/Page/Liff/MemberCard/PeriodSelect'

export default {
  mixins: [withFetcher],
  components: { Hero, Pagination, PeriodSelect },
  data: () => ({
    lastPage: 1,
  }),
  computed: {
    months () {
      return this.$route.query.months || '1'
    },
    page () {
      return +this.$route.query.page || 1
    },
    count () {
      return +this.$route.query.count || 10
    },
  },
  watch: {
    months: '$fetch',
    page: '$fetch',
    count: '$fetch',
  },
  methods: {
    setMonths (months) {
      const query =  { ...this.$route.query, months }

      return this.$router.push({ query })
    },
  },
}
